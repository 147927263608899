import * as React from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";
import { Link, navigate } from "gatsby"

export default function Contact() {
    return (
        <>
            <Seo title="Refund Policy" />
            <BrandHeading title="refund policy" />
            <section className="flex flex-wrap justify-evenly">
                <div className="text-justify font-gotu text-justify text-[.78rem] md:text-[0.80rem] tracking-wider md:tracking-[.07em] leading-[1.4rem] md:leading-[1.7rem] text-pas-gray max-w-3xl mx-auto">
                    <h3 className="text-2xl font-ovo text-xl md:text-2xl tracking-wider text-gray-900 mb-4">BESPOKE CAKES</h3>
                    {/* <h3 className="text-base mb-4 font-semibold"></h3> */}
                    <p className="mb-4">
                        To secure your order and booking date, we require a <span className="italic">50% non-refundable deposit</span> for all bespoke cakes. Orders are only confirmed upon receipt of deposit. Without the deposit, we will not hold your date.
                    </p>
                    <p className="mb-4">
                        When you order a bespoke cake from us, we make your cake with the utmost attention to detail to meet your requirements and spend many hours planning and preparing before even starting. For this reason, we hope you can understand that we are unable to provide refunds for all custom cake orders. We can, however, postpone the delivery of your cake to the next available date.</p>
                    <p className="mb-8">All of our cakes are made especially for you, therefore once production is under way, we are unable to offer a refund for cancellations.</p>

                    <h3 className="text-2xl font-ovo text-xl md:text-2xl tracking-wider text-gray-900 mb-4">NEXT DAY ORDERS</h3>
                    {/* <h3 className="text-base mb-4 font-semibold"></h3> */}
                    <p className="mb-4">Our cut-off time for next-day orders is at 1pm. The following cancellation fees for next day orders apply at the discretion of Pasteleria:
                    </p>
                    <p className="mb-4">Cancellation of orders before the cut-off time will be provided with a store credit in the form of an e-voucher emailed to you with a redemption code that can be entered in the checkout page. We hope you understand that orders are taken on a first in first served basis and bookings can fill up fast. If you cancel your order in the production line, your order has taken a place in the production line that could have gone to another customer, which means we miss out on both orders. </p>
                    <p className="mb-8">Cancellations received after the cut-off time will not be eligible for a refund. This is because we need to prepare components of the cake in advance, which means there has already been ingredients and labour invested in your order.</p>

                    <h3 className="text-2xl font-ovo text-xl md:text-2xl tracking-wider text-gray-900 mb-4">ALL OTHER ORDERS</h3>
                    <p className="mb-4">
                        The following cancellation fees for all other orders apply at the discretion of Pasteleria:
                    </p>
                    <p className="mb-4">For the same reasons mentioned above, cancellation of orders with more than 48 hours’ notice will be provided with a store credit in the form of an e-voucher emailed to you with a redemption code that can be entered in the checkout page. </p>
                    <p className="mb-8">Cancellations received with less than 48 hours’ notice will not be eligible for a refund.</p>

                    <h3 className="text-2xl font-ovo text-xl md:text-2xl tracking-wider text-gray-900 mb-4">STORE CREDIT</h3>
                    <p className="mb-8">Store credit is valid for 12 months from the date in which you cancelled your order. If the total of your new order is less than the store credit on your e-voucher, it will be paid entirely by your store credit. If the total is more than you have on your account, you will simply need to pay the remainder either by credit card or other forms of payment, just like with any other purchase.
                    </p>
                    <h3 className="text-2xl font-ovo text-xl md:text-2xl tracking-wider text-gray-900 mb-4">ALLERGIES</h3>
                    <p className="mb-4">We prepare our no added gluten and vegan products following procedures to prevent allergen cross-contamination. 
                                        We use no additional gluten, eggs or dairy ingredients and have designated utensils when making our no added gluten and vegan products. 
                                        However, while we strive to avoid cross-contamination in any way possible, we would like to emphasise that we do handle products containing 
                                        <span className="italic text-black"> gluten, dairy, eggs, nuts and soy</span> in our kitchen. 
                                        Please be advised that we cannot guarantee the effects of our no added gluten and vegan products to those who are allergic and have high sensitivities to gluten and dairy.</p>
                    <p className="mb-8">We have fully explained the handling of our no added gluten and vegan products and their risks. Therefore, we are no longer liable and responsible should you get any kind of reaction from our products. We will not offer any refunds for the same reason.</p>

                    <h3 className="text-2xl font-ovo text-xl md:text-2xl tracking-wider text-gray-900 mb-4">
                        IMPORTANT NOTICE</h3>
                    <p className="mb-4">
                        We are unable to provide a refund on a cake that has been consumed.
                    </p>
                    <p className="mb-4">Should we be unable to fulfill your order for any reason, we will notify you by phone or email as soon as possible and you will receive a full refund.
                    </p>
                    <p className="mb-4">Any cake placed outside during an event has the possibility of melting or deforming due to the heat. We are not liable for a cake once it has been delivered. </p>
                    <p className="mb-8">We are not liable or responsible for the cake once it has been picked up and left our kitchen. Please refer to our <Link to='/faqs#TRANSPORTATION' className="text-pas-orange underline underline-offset-4 decoration-pas-peach-darkest hover:decoration-2">FAQs</Link> page on how to transport your cake safely.
                    </p>
                </div>
            </section>
        </>
    )
}


